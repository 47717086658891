import { forEach } from 'lodash'
import { useEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import useSWR from 'swr'
import AdminTableButton from '../components/Admin/Button'
import Navbar from '../components/Layout/navbar'
import MockAccountInput from '../components/MockAccountInput'
import MigrationModal from '../components/Modal/MigrationModal'
import { RouteComponentWrapper } from '../components/RouteComponentWrapper'
import TransactionFailedModal from '../components/TransactionFailedModal'
import TransactionSubmittedModal from '../components/TransactionSubmittedModal'
import WppCard from '../components/WppCard'
import { BUILD_TYPE } from '../constants/common'
import {
  ROUTES_TO_BE_HIDDEN,
  RouteId,
  devRoutes,
  routes as originalRoutes,
} from '../constants/routes'
import { SUPPORTED_CHAIN_IDS, SupportedChainId } from '../constants/web3/supportedChainId'
import { useModalContext } from '../context/ModalContext'
import { useWeb3 } from '../context/Web3Context'
import { ModalId } from '../interfaces/modal'
import { useCashesData } from '../store/Asset/hooks'
import updateAllDelistedData from '../store/MigrationDetection/thunks/updateAllDelistedData'
import updateAllMW2Data from '../store/MigrationDetection/thunks/updateAllMW2Data'
import updateAllMigrationData from '../store/MigrationDetection/thunks/updateAllMigrationData'
import { useCoingeckoPriceData } from '../store/MultiChainMultiCallData/hooks'
import fetchMultichainMulticallData from '../store/MultiChainMultiCallData/thunks/fetchMultichainMulticallData'
import getCoingeckoPriceDataGlobal from '../store/MultiChainMultiCallData/thunks/getCoingeckoPriceData'
import { useTokenData, useUniV3PoolData } from '../store/MulticallData/hooks'
import fetchMulticalData from '../store/MulticallData/thunks/fetchMulticalData'
import { useExchangeRatesFromUniswapV3, usePythPrices } from '../store/Prices/hooks'
import fetchTokenPrices from '../store/Prices/thunks/fetchTokenPrices'
import getPythPriceData from '../store/Prices/thunks/getPythPriceData'
import getUnitPriceData from '../store/Prices/thunks/getUnitPriceData'
import { useBlock24hAgo } from '../store/Subgraph/hooks'
import getBlock24hAgo from '../store/Subgraph/thunks/getBlock24hAgo'
import getSubgraphData from '../store/Subgraph/thunks/getSubgraphData'
import { useAppDispatch } from '../store/hooks'

type Props = {
  children?: React.ReactNode
}

const useGetSubgraphData = () => {
  const { chainId, chainInfo } = useWeb3()
  const dispatch = useAppDispatch()
  const block24hAgo = useBlock24hAgo()
  useEffect(() => {
    dispatch(
      getBlock24hAgo({
        chainId,
        chainInfo,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, chainInfo])

  useEffect(() => {
    if (chainInfo.id !== SupportedChainId.BSC_TESTNET) {
      dispatch(
        getSubgraphData({
          chainId,
          chainInfo,
          block24hAgo,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block24hAgo, chainId, chainInfo])
}

const usePollingMultichainData = () => {
  const coinGeckoPriceData = useCoingeckoPriceData()
  const { readonlyProvider, chainId, account } = useWeb3()
  const dispatch = useAppDispatch()
  useSWR(
    [chainId, coinGeckoPriceData, account, readonlyProvider, 'fetchCurrentMultiChainMulticallData'],
    ([chainId, coinGeckoPriceData, account, readonlyProvider]) => {
      dispatch(
        fetchMultichainMulticallData({
          currentChainId: chainId,
          coingeckoPriceData: coinGeckoPriceData,
          account,
          readonlyProvider,
          chainIds: [chainId],
        })
      )
    },
    {
      refreshInterval: 10 * 1000,
    }
  )

  useSWR(
    [chainId, coinGeckoPriceData, account, readonlyProvider, 'fetchAllMultiChainMulticallData'],
    ([chainId, coinGeckoPriceData, account, readonlyProvider]) => {
      forEach(SUPPORTED_CHAIN_IDS, (chainId_) => {
        if (chainId === chainId_) {
          return
        }
        dispatch(
          fetchMultichainMulticallData({
            currentChainId: chainId,
            coingeckoPriceData: coinGeckoPriceData,
            account,
            readonlyProvider,
            chainIds: [chainId_],
          })
        )
      })
    },
    {
      refreshInterval: 120 * 1000,
    }
  )
}

const usePollingTokenPrices = () => {
  const tokenData = useTokenData()
  const exchangeRatesFromUniswapV3 = useExchangeRatesFromUniswapV3()
  const pythPrices = usePythPrices()
  const coingeckoPrices = useCoingeckoPriceData()
  const uniswapV3PoolData = useUniV3PoolData()
  const { readonlyProvider, chainId } = useWeb3()
  const dispatch = useAppDispatch()
  useSWR(
    [chainId, 'getCoinGeckoPriceDataGlobal'],
    () => {
      dispatch(getCoingeckoPriceDataGlobal())
    },
    {
      refreshInterval: 20 * 1000,
    }
  )
  useSWR(
    [chainId, 'getPythPriceData'],
    ([chainId]) => {
      dispatch(getPythPriceData({ chainId }))
    },
    {
      refreshInterval: 20 * 1000,
    }
  )

  useSWR(
    [chainId, readonlyProvider, uniswapV3PoolData, 'getUnitPriceData'],
    ([chainId, readonlyProvider, uniswapV3PoolData]) => {
      dispatch(
        getUnitPriceData({ chainId, readonlyProvider, uniswapV3PoolData: uniswapV3PoolData })
      )
    },
    {
      refreshInterval: 20 * 1000,
    }
  )

  useEffect(() => {
    if (coingeckoPrices) {
      dispatch(
        fetchTokenPrices({
          chainId,
          tokenData,
          exchangeRatesFromUniswapV3,
          pythPrices,
          coingeckoPrices,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, coingeckoPrices, exchangeRatesFromUniswapV3, pythPrices, tokenData])
}

const AppBackground: React.FC<Props> = ({ children }): React.ReactElement => (
  <div className="bg-wombatBackgroundMobile bg-cover bg-fixed bg-center bg-no-repeat md:bg-wombatBackground">
    {children}
  </div>
)

export default function App() {
  const {
    modalState,
    actions: { closeModal },
  } = useModalContext()
  const { chainId, account } = useWeb3()
  const isDev =
    localStorage.getItem('isDev') !== null ? localStorage.getItem('isDev') === 'true' : false
  const routes = isDev ? devRoutes : originalRoutes
  const dispatch = useAppDispatch()
  useSWR(
    [chainId, account, 'fetchMulticalData'],
    ([chainId, account]) => {
      dispatch(
        fetchMulticalData({
          account,
          chainId,
        })
      )
    },
    {
      refreshInterval: 10 * 1000,
    }
  )
  const { lpTokenToTokenRates: lpTokenToTokenRatesWad } = useCashesData()
  usePollingMultichainData()
  usePollingTokenPrices()
  useEffect(() => {
    dispatch(
      updateAllMigrationData({
        chainId,
        account,
        lpTokenToTokenRatesWad,
      })
    )
  }, [account, chainId, dispatch, lpTokenToTokenRatesWad])

  useEffect(() => {
    dispatch(
      updateAllDelistedData({
        chainId,
        account,
      })
    )
    dispatch(
      updateAllMW2Data({
        chainId,
        account,
      })
    )
  }, [account, chainId, dispatch])

  useGetSubgraphData()

  return (
    <AppBackground>
      <BrowserRouter>
        <Navbar>
          <WppCard />
          <div className="md:p-8 md:pt-0">
            <Routes>
              {Object.entries(routes)
                .filter(([routeId]) => !ROUTES_TO_BE_HIDDEN[routeId as RouteId]?.includes(chainId))
                .map(([, route]) => (
                  <Route
                    key={route.name}
                    path={route.path}
                    element={
                      <RouteComponentWrapper>
                        {route.component && <route.component />}
                      </RouteComponentWrapper>
                    }
                  />
                ))}
              <Route path="*" element={<Navigate to={routes.SWAP.path} />} />
            </Routes>
            {process.env.NEXT_PUBLIC_BUILD_TYPE !== BUILD_TYPE.PROD && (
              <div className="fixed bottom-6 left-6 z-20 space-y-2">
                <AdminTableButton />
                <MockAccountInput />
              </div>
            )}
          </div>
        </Navbar>
        {modalState.currentModalId === ModalId.TRANSACTION_SUBMITTED && (
          /** @todo refactor TransactionSubmittedModal  */
          <TransactionSubmittedModal
            isOpen
            hash={modalState.payload?.transactionHashes && modalState.payload.transactionHashes[0]}
            chainId={chainId}
            isAddTokenToWallet={false}
            onClose={closeModal}
          />
        )}
        {modalState.currentModalId === ModalId.TRANSACTION_FAILED && (
          <TransactionFailedModal isOpen onClose={closeModal} />
        )}
        {modalState.currentModalId === ModalId.MIGRATION_WAIT_FOR_CONFIRMATION && (
          <MigrationModal isOpen onClose={closeModal} />
        )}
      </BrowserRouter>
    </AppBackground>
  )
}
