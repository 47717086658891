import clsx from 'clsx'
import { useWeb3 } from '../../context/Web3Context'
import { useWppNeedAuth, useWpPointsForUser } from '../../store/WpPoints/hooks'
import { formatNumberUSLocale } from '../../utils/numberFormat'

const WppCard = () => {
  const { account } = useWeb3()
  const wpPoints = useWpPointsForUser(account || '')
  const wppNeedAuth = useWppNeedAuth()
  if (!account) {
    return <></>
  }

  return (
    <div className={'flex w-full items-start justify-end px-[26px] md:px-8'}>
      <div
        className={clsx(
          'w-full bg-[#5178bb] p-0',
          'rounded-md border-1 border-[#342A1A]',
          'bg-[#ECDAF4] text-[#50473E]',
          'w-full md:mr-[38px] md:w-[208px]',
          ' flex flex-col justify-between'
        )}
      >
        <div className={clsx('p-[10px]')}>
          <a
            href={'https://www.wompets.com/'}
            target={'_blank'}
            className="m-auto flex w-full justify-between"
          >
            <div className="flex items-center gap-1 font-Helvetica text-[14px]">WP Points</div>
            <div className="flex items-center font-Helvetica text-[14px]">
              {formatNumberUSLocale(`${wpPoints}` ?? '0')}
            </div>
          </a>
          {wppNeedAuth && !wpPoints && (
            <div className={'font-Helvetica text-[12px] opacity-60'}>
              Please login to activate WPP at{' '}
              <a href={'https://www.wompets.com/'} className={'underline'} target={'_blank'}>
                Wompets.com
              </a>
            </div>
          )}
        </div>
        <div
          className={clsx(
            'aspect-[207/43] w-full bg-wppbg',
            '[background-repeat:no-repeat] [background-size:cover]'
          )}
        ></div>
        {/*<Image*/}
        {/*  className={"w-full aspect-[207/43]"}*/}
        {/*  src={bg} width={207} height={43}></Image>*/}
      </div>
    </div>
  )
}

export default WppCard
