/**

Calculates the efficiency percentage of bribes based on WOM price, total bribe amount, and WOM emission.
@param {number | null} womPrice - The price of WOM token.
@param {string} totalBribeInUsdInCurrentEpoch - The total bribe amount in USD for the current epoch.
@param {number} womEmission - The WOM emission amount.
@returns {string} - The efficiency percentage of bribes.
*/

export const getBribeEfficiencyPercentage = (
  womPrice: number | null,
  totalBribeInUsdInCurrentEpoch: string,
  womEmission: number
) => {
  if (
    !womPrice ||
    totalBribeInUsdInCurrentEpoch === '-' ||
    Number(totalBribeInUsdInCurrentEpoch) <= 0
  )
    return '-'
  return `${(
    (Number(womEmission) * womPrice * 100) /
    Number(totalBribeInUsdInCurrentEpoch)
  ).toFixed(2)}%`
}
